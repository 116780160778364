$crimson: #f7caac;
$light-blue: #8da9db;

.cart-modal {
  display: flex;
  align-items: center;

  &-info {
    padding: 0.5rem;
    justify-content: center;
    text-align: center;
  }

  &-img {
    padding: 1rem 0.5rem;
  }

  &-name {
    margin: 0;
    color: #000;
    font-weight: 600;
  }

  &-price {
    margin: 0;
    color: #303030;
  }

  &-close {
    position: absolute !important;
    top: 1rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
  }

  &-actions {
    width: 100%; 
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;
    flex-direction: row;
    row-gap: 0.2rem;


    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #f2f2f2;
      border-radius: 0;
      border: 0;
      height: 40px;
      text-align: center;
    }

    button {
      color: #000;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      text-decoration: none;
      cursor: pointer;
      border: none;
      width: 100%;
    }

    &-decrement-btn {
      background-color: $crimson;
    }

    &-increment-btn {
      background-color: $light-blue;
    }
  }
}

.category-section {
  font-weight: 700;
  text-align: left;
  text-transform: capitalize; 
}