@import "../../../styles/variables.scss";

// .menu-container {
//   display: flex;
//   font-size: 14px;
//   align-items: center;
//   flex-direction: column;
//   a {
//     color: #fff;
//     text-decoration: none;
//     margin-left: 1rem;

//     &.active {
//       color: #283f55;
//       border: 2px solid $appPrimaryColor;
//       font-size: 18px;
//       font-weight : bold;
//     }
//   }
// }

.side-bar {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 11vh);
  overflow: auto;
  background-color: #25BCCA;
  color: #fff;
  padding-top: 15px;
  a {
    color: #fff;
    text-decoration: none;
    padding-left: 1rem;

    &.active {
      color: #283f55;
      background-color: $linkWater;
      font-size: 18px;
      font-weight : bold;
    }
  }
  &-content {
    padding: 10px;
  }
}
@media screen and (max-width: 900px) {
  .side-bar{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    padding-top: 15px;
    &-content {
      padding: 5px;
    }
  }
  
}
