.verification-status {
  font-size: 1rem;

  color: #4fe24a;
  text-align: center;
  margin-top: 6rem;
}

.header {
  color: #000;
  font-family: Inter;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.content {
  color: var(--Gable-Green, #1c2e36);

  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.login-content {
  color: var(--Gable-Green, #1c2e36);
  font-family: Mulish;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.wrapper {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
@media screen and (max-width: 450px){
  .header {
    display: flex;
    justify-content: center;
  }
} 
