body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}
