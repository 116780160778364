.dashboard {
  padding: 1rem 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2rem;

  &-tabs {
    position: sticky;
    // top: 0rem;
    background-color: #fff;
  } 
}

@media screen and (max-width: 1100px) {
  .dashboard {
    display: flex;
    flex-direction: row !important;

    &-cart {
      width: 55%;
      flex-wrap: wrap;
    }
    &-summary {
      width: 45%;
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 882px) {
  .dashboard {
    display: flex;
    flex-direction: column !important;

    &-cart {
      width: 100%;
      flex-wrap: wrap;
    }
    &-summary {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
