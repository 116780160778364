.filter-cloth-list {
    padding: 5px 20px;
    max-height: 18vh;
    overflow-y: auto;
}
@media screen and (min-width: 200px)and (max-width: 359px){
    .filter-cloth-list {
        max-height: 34vh;
    }
}
@media screen and (width: 360px){
    .filter-cloth-list {
        max-height: 20vh;
    }
}
@media screen and (min-width: 370px){
    .filter-cloth-list {
        max-height: 34vh;
    }
}
@media screen and (min-width: 375px){
    .filter-cloth-list {
        max-height: 18vh;
    }
}
@media screen and (min-width: 390px){
    .filter-cloth-list {
        max-height: 32vh;
    }
}
@media (min-width: 401px) and (max-width: 499px) {
    .filter-cloth-list {
        max-height: 38vh;
    }
} 
@media (min-width: 501px) and (max-width: 539px) {
    .filter-cloth-list {
        max-height: 34vh;
    }
}
@media screen and (width: 540px){
    .filter-cloth-list {
        max-height: 30vh;
    }
}     
@media screen and (min-width: 600px){
    .filter-cloth-list {
        max-height: 52vh;
    }
}
@media screen and (min-width: 750px){
    .filter-cloth-list {
        max-height: 30vh;
    }
}
@media screen and (min-width: 800px){
    .filter-cloth-list {
        max-height: 42vh;
    }
}
@media (min-width: 901px) and (max-width: 1000px){
    .filter-cloth-list {
        max-height: 62vh;
    }
}
@media (min-width: 1024px){
    .filter-cloth-list {
        max-height: 62vh;
    }
}
@media (min-width: 1025px) and (min-height: 690px ){
    .filter-cloth-list {
        max-height: 34vh;
    }
}