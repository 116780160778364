.sideBar {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 89vh;
  background-color: #25bcca;
  color: #fff;
  // padding: 15px;
  a {
    color: #fff;
    text-decoration: none;
    padding-left: 1rem;
  }
}
.sideBarContent {
  margin-top: 20px;
}
.active {
  color: #283f55 !important;
  background-color: #d5e9fa;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .sideBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    overflow-x: auto;
    padding-top: 15px;
  }
}
