.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &-img {
        max-width: 80%;
        max-height: 100%;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    &-button {
        flex: 1;
        cursor: pointer;
    }
}
@media screen and (max-width: 450px){
    .main {
      height: 70%;
    }
} 
@media screen and (max-width: 1100px){
    .main {
      height: 86%;
    }
} 
@media screen and (max-width: 1550px){
    .main {
      height: 86%;
    }
} 

