@import "./variables.scss";

.btn-optival {
  text-transform: none !important;
  background-color: $appPrimaryColor !important;
  color: $gableGreen !important;
  font-size: 14px !important;
}

.label-grey {
  color: $hitGrey;
}
