.dashboard {
    table {
      border: none;
      border-collapse: collapse;
    }
  
    &-user-info {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      list-style: none;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      &-name {
        display: flex;
        width: 50%;
        img {
          margin-right: 0.5rem;
        }
        span {
          font-weight: 700;
          color: #8e267c;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 95%;
        }
      }
  
      &-phone {
        width: 50%;
        display: flex;
        justify-content: end;
        img {
          margin-right: 0.5rem;
        }
        margin-left: auto;
      }
      &-address {
        padding-top: 0.5rem;
        margin: 0;
        text-align: left;
      }
    }
  
    &-cart {
      width: 69%;
    }
  
    &-summary {
      width: 40%;
      border-radius: 1rem;
      padding: 1.5rem 1rem;
      background: #f2f2f2;
      height: max-content;
      position: sticky;
      top: 5%;
  
      &-category {
        width: 100%;
        margin-bottom: 0.25rem;
      }
  
      &-order-header {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        list-style: none;
  
        li {
          width: 30%;
        }
      }
  
      &-list {
        table {
          width: 100%;
        }
        min-height: calc(100vh - 265px);
        display: flex;
        width: 100%;
        flex-direction: column;
      }
  
      &-total {
        margin-top: auto;
        &-table {
          margin-top: 0.25rem;
          width: 100%;
        }
      }
  
      td,
      th {
        padding: 0.25rem;
      }
  
      tr > td {
        text-align: left;
      }
      tr > th {
        text-align: left;
      }
    }
  
    &-tableActions {
      text-align: center;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      list-style: none;
      flex-direction: row;
      row-gap: 0.2rem;
      &-input {
        width: 100%;
        padding: 0.5rem 0.2rem;
        border: 1px solid #bebebe;
        border-radius: 0;
        height: 40px;
        text-align: center;
        border-radius: 10px;
        pointer-events: none;
      }
      button {
        color: #000;
        font-size: 1rem;
        padding: 0.25rem;
        cursor: pointer;
        border: none;
        width: 25px;
        text-align: center;
        border-radius: 50%;
        height: 25px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-decrement-btn {
        background-color: #f7caac;
      }
      &-increment-btn {
        background-color: #8da9db;
      }
      &-edit-btn {
        width: 25px;
        text-align: center;
        border-radius: 50%;
        height: 25px;
        background-color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
        }
      }
    }
  
    &-category-section {
      text-align: left;
      background: #9c27b0;
      padding: 0.25rem 0.5rem;
      border-radius: 5px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  