.Header {
    color: #000;


    font-family: Inter;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

}
.contents {
    display: flex;
   

    .left-section {
        flex: 1;
        width: 155;
        height: 195;
       

        .aspect {
            width: 155;
            height: 195;
        }
    }

    .right-section {
        flex: 1;
       
        

        .organization-details {
            margin-bottom: 10px;

            .content {
                font-weight: bold;
            }

            .subtitle {
                color: #333;
            }
        }
    }
    
}
 .organization-details {
     margin-bottom: 10px;

     .content {
         font-weight: bold;
     }

     .subtitle {
         color: #333;
     }
 }