.user-info {
    position: relative;
    width: 100%;
    top: -10px;
    overflow: hidden !important;
    &-content {
        overflow-y: auto;
        max-height: calc(100vh - 200px);
    }
    &-footer {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); 
        width: 100%;
        padding: 20px;
        z-index: 1;
    }
}